<template>
  <td v-for="(value, keyName) in row" :key="keyName" v-show="checkList.includes(keyName)" :class="{'py-0': keyName === 'reason'}" > 
    <div class="table-data-container click" v-if="keyName=='事業所'" @click="goto(row['LBC'])">
      {{ value==null || value=="" ?'未命名':value }}
    </div>
    <div class="table-data-container" v-if="keyName!='事業所' && keyName!='LBC'">
      <div v-html="value"></div>
    </div>
  </td>
</template>

<script>
export default {
  name: 'ListTableRow',
  props: {
    row: Object,
    checkList: Object
  },
  data() {
    return {
      isPopup: false
    }
  },
  methods: {
    togglePopup: function() {
      setTimeout(() => {
        this.isPopup = !this.isPopup
      }, 10)
    },
    goto(id){
      this.$router.push({path:'/build4/'+id});
    }
  },
}
</script>

<style lang="scss" scoped>
.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table-data-container {
  max-height: 15rem;
  overflow-y: auto;
}

.focus-on .focused a {
  color: #fff;
}

.focus-on tr:not(.focused) .btn-white:hover {
  color: #fff;
  background-color: #003f2d;
  border-color: #003f2d;
}

.focus-on .focused a:hover {
  color: #003f2d;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
.table.table-striped tr td:first-child{background:#80BBAD !important;color:#000 !important;}

.table.table-striped tr.emptyd td:first-child{background:#003f2d !important;color:#fff}

.click{text-decoration: underline;}
 .table-data-container div, .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
</style>